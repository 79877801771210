const Config = {
    development: {
        API_BASE_URL: "http://0.0.0.0:8000",
        CDN_BASE_URL: "http://localhost:3001",
        DEFAULT_END_USER_ID: "1",
        LOCAL_STORAGE_PREFIX: "AIchat60433_",
    },
    production: {
        API_BASE_URL: "https://d1ktr0g2fmvihg.cloudfront.net",
        CDN_BASE_URL: "https://d1hli9zkqkacyc.cloudfront.net",
        DEFAULT_END_USER_ID: "ADMIN_TEST_USER",
        LOCAL_STORAGE_PREFIX: "AIchat60433_"
    }
};

export default Config[process.env.NODE_ENV];
