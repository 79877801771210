import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircle from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ConstructionIcon from '@mui/icons-material/Construction';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import TerminalIcon from '@mui/icons-material/Terminal';
import axios from 'axios';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router-dom';

const AgentsApp = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAgent, setNewAgent] = useState({
    agent_name: '',
    channel: '',
    background_info: '',
    allowed_origins: [''],
    common_queries: [''],
    welcome_message: '',
  });
  const [editAgent, setEditAgent] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [setupDialog, setSetupDialog] = useState({ open: false });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, agentId: null });
  const navigate = useNavigate();
  const showSnackbar = (message, severity) => setSnackbar({ open: true, message, severity });
  const handleCloseSnackbar = () => setSnackbar({ open: false, message: '', severity: 'success' });
  const { secretToken, apiBaseUrl, cdnBaseUrl, userId } = useAuth();

  const codeRef = useRef(null);

  const agentChannels = [
    { value: 'chat', label: 'Chat' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
  ];

  const channelColors = {
    chat: '#e0f7fa',
    email: '#f1f8e9',
    phone: '#ffe0b2',
  };

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        Authorization: `Bearer ${secretToken}`,
      },
    });
  }, [apiBaseUrl, secretToken]);

  const fetchAgents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/agents', {
        params: { user_id: userId }
      });
      setAgents(response.data);
    } catch (error) {
      showSnackbar('Failed to fetch agents', 'error');
    } finally {
      setLoading(false);
    }
  }, [axiosInstance, userId]);

  const handleOpenDialog = (agent = null) => {
    setNewAgent(
      agent || {
        agent_name: '',
        channel: '',
        background_info: '',
        allowed_origins: [''],
        common_queries: [''],
        welcome_message: '',
      }
    );
    setEditAgent(agent);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditAgent(null);
  };

  const handleQueryChange = (index, value) => {
    const updatedQueries = [...newAgent.common_queries];
    updatedQueries[index] = value;
    setNewAgent({ ...newAgent, common_queries: updatedQueries });
  };

  const addQueryField = () => {
    setNewAgent({ ...newAgent, common_queries: [...newAgent.common_queries, ''] });
  };

  const removeQueryField = (index) => {
    const updatedQueries = newAgent.common_queries.filter((_, i) => i !== index);
    setNewAgent({ ...newAgent, common_queries: updatedQueries });
  };

  const handleOriginChange = (index, value) => {
    const updatedOrigins = [...newAgent.allowed_origins];
    updatedOrigins[index] = value;
    setNewAgent({ ...newAgent, allowed_origins: updatedOrigins });
  };

  const addOriginField = () => {
    setNewAgent({ ...newAgent, allowed_origins: [...newAgent.allowed_origins, ''] });
  };

  const removeOriginField = (index) => {
    const updatedOrigins = newAgent.allowed_origins.filter((_, i) => i !== index);
    setNewAgent({ ...newAgent, allowed_origins: updatedOrigins });
  };

  const saveAgent = async () => {
    try {
      if (editAgent) {
        await axiosInstance.put(`/agents/${editAgent.agent_id}`, {...newAgent, user_id: userId});
        showSnackbar('Agent updated successfully', 'success');
      } else {
        if (newAgent.common_queries.join('') === '') {
          newAgent.common_queries = [];
        }
        if (newAgent.allowed_origins.join('') === '') {
          newAgent.allowed_origins = [];
        }
        await axiosInstance.post('/agents', {...newAgent, user_id: userId});
        showSnackbar('Agent added successfully', 'success');
      }
      fetchAgents();
      handleCloseDialog();
    } catch (error) {
      showSnackbar('Failed to save agent', 'error');
    }
  };

  const confirmDeleteAgent = (agentId) => {
    setDeleteDialog({ open: true, agentId });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({ open: false, agentId: null });
  };

  const handleDeleteAgent = async (agentId) => {
    try {
      await axiosInstance.delete(`/agents/${deleteDialog.agentId}`);
      fetchAgents();
      showSnackbar('Agent deleted successfully', 'success');
    } catch (error) {
      showSnackbar('Failed to delete agent', 'error');
    } finally {
      setDeleteDialog({ open: false, agentId: null });
    }
  };

  const copyToClipboard = () => {
    if (codeRef.current) {
      codeRef.current.select();
      document.execCommand('copy');
      showSnackbar('Successfully copied to clipboard', 'success');
    }
  };

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const generateEmbedCode = () => {
    const jsCode = `(function() {
        var script = document.createElement('script');
        script.src = '${cdnBaseUrl}/chat.js';
        script.onload = function() {
          new EmbedChatbot('${btoa(apiBaseUrl)}', '${btoa(cdnBaseUrl)}', '${btoa(secretToken)}', '${btoa(setupDialog.agentId)}');            
        };
        document.body.appendChild(script);
    })();`;
    const minifiedJsCode = jsCode.replace(/\s{2,}/g, " ").replace(/\s*([{};,:])\s*/g, "$1").replace(/\n/g, ""); 
    const embedCode = `<script type="text/javascript">${minifiedJsCode}</script>`;
    return embedCode;
  }

  const generateInlineCodeElement = (code, inline=false) => {
    return (<Box
              sx={{
                backgroundColor: '#5c5c5c',
                padding: inline ? '4px': '8px',
                borderRadius: '8px',
                display: 'inline-block',
              }}>
                <Typography sx={{fontSize: '12px', fontFamily: 'monospace', color: '#d4d4d4'}}>{code}</Typography>
            </Box>);
  }


  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" sx={{ backgroundColor: '#f0f0f0' }}>
      <Container
        maxWidth="md"
        sx={{ backgroundColor: 'rgb(246, 246, 246)', borderRadius: '15px', padding: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', height: '90vh' }}
      >
        <Box display="flex" justifyContent="space-between" marginBottom="20px">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Your Agents</Typography>
          <Button variant="outlined" startIcon={<AddCircle />} onClick={() => handleOpenDialog()}>
            Add New Agent
          </Button>
        </Box>

        {loading ? (
          <CircularProgress />
        ) : agents.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
            <HourglassEmpty fontSize="large" color="disabled" sx={{ fontSize: 80 }} />
            <Typography variant="body1" color="textSecondary" mt={2}>
              You don't have any agents, get started by creating an agent above!
            </Typography>
          </Box>
        ) : (
          <List>
            {agents.map((agent) => (
              <ListItem key={agent.agent_id} sx={{ backgroundColor: '#fff', borderRadius: '10px', marginBottom: '10px', marginLeft: '8px', flexDirection: 'column' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" alignItems="center">
                    <ListItemText
                      primary={
                        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                          {agent.agent_name}
                        </Typography>
                      }
                    />
                    <Chip
                      label={agent.channel}
                      sx={{
                        backgroundColor: channelColors[agent.channel],
                        color: '#000',
                        marginLeft: '10px',
                        fontSize: '1rem',
                        padding: '5px',
                      }}
                      size="medium"
                    />
                  </Box>
                  <Box>
                    <IconButton onClick={() => handleOpenDialog(agent)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => confirmDeleteAgent(agent.agent_id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" marginTop="10px">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/agents/${agent.agent_id}/actions`)}
                    sx={{
                      marginBottom: '5px',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      width: 'calc(50% - 5px)',
                      height: '60px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <ConstructionIcon sx={{ marginRight: '10px' }} />
                    <Box>
                      <Typography variant="body1">Manage Actions</Typography>
                      <Typography variant="caption" color="textSecondary">Manage actions your agent can take.</Typography>
                    </Box>
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/agents/${agent.agent_id}/conversations`)}
                    sx={{
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      width: 'calc(50% - 5px)',
                      height: '60px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    <QuestionAnswerIcon sx={{ marginRight: '10px' }} />
                    <Box>
                      <Typography variant="body1">View Conversations</Typography>
                      <Typography variant="caption" color="textSecondary">See conversations your users started.</Typography>
                    </Box>
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/agents/${agent.agent_id}/test`)}
                    sx={{
                      marginBottom: '5px',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      width: 'calc(50% - 5px)',
                      height: '60px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <ReplyAllIcon sx={{ marginRight: '10px' }} />
                    <Box>
                      <Typography variant="body1">Test Agent</Typography>
                      <Typography variant="caption" color="textSecondary">Start a conversation with your agent.</Typography>
                    </Box>
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => setSetupDialog({ open: true, agentId: agent.agent_id })}
                    sx={{
                      marginBottom: '5px',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      width: 'calc(50% - 5px)',
                      height: '60px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    <TerminalIcon sx={{ marginRight: '10px' }} />
                    <Box>
                      <Typography variant="body1">Deploy Agent</Typography>
                      <Typography variant="caption" color="textSecondary">Deploy the agent on your website.</Typography>
                    </Box>
                  </Button>
                </Box>
              </ListItem>
            ))}
          </List>
        )}

        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>{editAgent ? 'Edit Agent' : 'Add New Agent'}</DialogTitle>
          <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
            <TextField
              fullWidth
              label="Agent Name"
              value={newAgent.agent_name}
              onChange={(e) => setNewAgent({ ...newAgent, agent_name: e.target.value })}
              margin="normal"
            />
            <TextField
              select
              fullWidth
              label="Channel"
              value={newAgent.channel}
              onChange={(e) => setNewAgent({ ...newAgent, channel: e.target.value })}
              margin="normal"
            >
              {agentChannels.map((channel) => (
                <MenuItem key={channel.value} value={channel.value}>{channel.label}</MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              multiline
              label="Background Info"
              value={newAgent.background_info}
              onChange={(e) => setNewAgent({ ...newAgent, background_info: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              multiline
              label="Welcome Message"
              value={newAgent.welcome_message}
              onChange={(e) => setNewAgent({ ...newAgent, welcome_message: e.target.value })}
              margin="normal"
            />

            {/* Common Queries Field */}
            <Box sx={{ padding: 2, backgroundColor: '#f7f7f7', borderRadius: '10px', marginTop: '15px' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>Common Queries</Typography>
              {newAgent.common_queries.map((query, index) => (
                <Box key={index} display="flex" alignItems="center" marginBottom="10px">
                  <TextField
                    fullWidth
                    label={`Query ${index + 1}`}
                    value={query}
                    onChange={(e) => handleQueryChange(index, e.target.value)}
                    sx={{ marginRight: '10px' }}
                  />
                  <IconButton onClick={() => removeQueryField(index)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={addQueryField} startIcon={<AddCircle />}>
                Add Query
              </Button>
            </Box>

            {/* Allowed Origins Field */}
            <Box sx={{ padding: 2, backgroundColor: '#f7f7f7', borderRadius: '10px', marginTop: '15px' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>Allowed Origins</Typography>
              {newAgent.allowed_origins.map((origin, index) => (
                <Box key={index} display="flex" alignItems="center" marginBottom="10px">
                  <TextField
                    fullWidth
                    label={`Origin ${index + 1}`}
                    value={origin}
                    onChange={(e) => handleOriginChange(index, e.target.value)}
                    sx={{ marginRight: '10px' }}
                  />
                  <IconButton onClick={() => removeOriginField(index)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={addOriginField} startIcon={<AddCircle />}>
                Add Origin
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="error">Cancel</Button>
            <Button onClick={saveAgent}>{editAgent ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </Dialog>

        {/* Setup Dialog */}
        <Dialog open={setupDialog.open} onClose={() => setSetupDialog({ open: false })} maxWidth="sm" fullWidth>
          <DialogTitle>Setup Agent in Your Website</DialogTitle>
          <DialogContent>
            <Typography sx={{padding: 1}}><strong>1.</strong> First, you need to make sure your website is added to allowed origins in your <a href="/agents">Agent settings</a>.</Typography>
            <Typography sx={{padding: 1}}><strong>2.</strong> If you want to personalize the user experience, you will need to set the following local storage variable any time before the user starts chat:</Typography>
            {generateInlineCodeElement("localStorage.setItem('QUljaGF0NjA0MzM_userId', 'CURRENT_USER_ID');")}
            <Typography sx={{padding: 1, paddingTop: 2}}><strong>3.</strong> Add the following code inside your {generateInlineCodeElement("<body></body>", true)} tags in your HTML:</Typography>
            <TextField
              fullWidth
              multiline
              inputRef={codeRef}
              value={generateEmbedCode()}
              onClick={() => codeRef.current.select()}
              InputProps={{ readOnly: true }}
              margin="none"
              rows={6}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={copyToClipboard} startIcon={<ContentCopyIcon />}>
              Copy to Clipboard
            </Button>
            <Button onClick={() => setSetupDialog({ open: false })} color="error">Close</Button>
          </DialogActions>
        </Dialog>

        {/* Delete confirmation dialog */}
        <Dialog open={deleteDialog.open} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this agent? This action cannot be undone.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
            <Button onClick={handleDeleteAgent} color="error">Delete</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AgentsApp;
